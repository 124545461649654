[data-v-d525ced6] .el-dialog {
  max-height: calc(100% - 20px);
  background: #f9fafc !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 32px 60px;
  border-radius: 10px;
  display: block;
}
[data-v-d525ced6] .el-dialog .el-dialog__header {
    line-height: 34px;
    -webkit-box-shadow: rgba(12, 31, 80, 0.08) 0px 4px 10px 0px;
            box-shadow: rgba(12, 31, 80, 0.08) 0px 4px 10px 0px;
    background: #fff !important;
    border-radius: 20px 24px 10px 10px;
    overflow: hidden;
}
[data-v-d525ced6] .el-dialog .el-dialog__body {
    -webkit-box-shadow: rgba(12, 31, 80, 0.08) 0px 4px 10px 0px;
            box-shadow: rgba(12, 31, 80, 0.08) 0px 4px 10px 0px;
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px !important;
    height: calc(100% - 140px);
}
[data-v-d525ced6] .el-dialog .el-dialog__footer {
    text-align: center;
}
[data-v-d525ced6] .el-dialog .el-dialog__headerbtn .el-dialog__close {
    color: #000;
}
.my-viewer-title-img[data-v-d525ced6] {
  width: 100%;
  height: 20px;
  background-image: url("https://file.qingflow.com/assets/widget/theme/header0.png");
  background-size: cover;
}
.my-viewer-title[data-v-d525ced6] {
  color: #000;
  width: 100%;
  padding: 16px;
  height: 34px;
}
.my-viewer-title span[data-v-d525ced6] {
    font-family: BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Microsoft Yahei UI", "Microsoft Yahei", sans-serif font-feature-settings "tnum", "tnum";
    font-size: 20px;
    font-weight: 600;
}
.no-form[data-v-d525ced6] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: var(--rootFontSize3);
  color: #606266;
}
[data-v-d525ced6] .el-tabs__item {
  font-weight: 600;
  font-size: 14px !important;
}
[data-v-d525ced6] .cus-data-viewer {
  height: 100%;
}
.boday-header[data-v-d525ced6] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.boday-header .table-search-state[data-v-d525ced6] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.table-toolbar[data-v-d525ced6] {
  margin-left: 18px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  height: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 4px 0;
}
.table-toolbar .table-toolbar-button[data-v-d525ced6] {
    font-size: 14px;
    cursor: pointer;
    color: #494f57;
    font-weight: 600;
    line-height: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 3px;
    width: auto;
    padding: 0 8px;
    height: 100%;
}
.table-toolbar .table-toolbar-button i[data-v-d525ced6] {
      margin-right: 4px;
}
.table-toolbar .table-toolbar-button[data-v-d525ced6]:hover {
      background-color: #eee;
}
.header-tab[data-v-d525ced6] {
  width: 100%;
  height: 52px;
  background-color: #fff;
  border-bottom: 1px solid #e4e7ed;
  border-top: 1px solid #e4e7ed;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header-tab .header-tab-title[data-v-d525ced6] {
    font-size: 16px;
    font-weight: 600;
    margin-left: 20px;
    height: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    line-height: 60px;
}
.header-tab .header-tab-button[data-v-d525ced6] {
    margin-right: 20px;
    border-left: 1px solid #e4e7ed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    width: 300px;
}
.header-tab .header-tab-button .header-tab-button-icon[data-v-d525ced6] {
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      border-radius: 3px;
      margin: 0 16px;
      padding: 6px;
      cursor: pointer;
}
.header-tab .header-tab-button .header-tab-button-icon[data-v-d525ced6]:hover {
        background-color: #eee;
}
.header-tab .header-tab-button .header-tab-button-icon i[data-v-d525ced6] {
        font-size: 24px;
}
[data-v-d525ced6] .el-tabs {
  top: 12px;
  left: 140px;
  z-index: 10;
  position: absolute;
}
[data-v-d525ced6] .el-tabs .is-active {
    background-color: #f9fafc;
}
[data-v-d525ced6] .el-tabs__header {
  margin-bottom: 0px;
}
.body-tab[data-v-d525ced6] {
  width: 100%;
  height: calc(100% - 54px);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 16px;
  background-color: #f9fafc;
}
[data-v-d525ced6] .table-header-style th {
  background-color: #f9fafc;
  font-weight: 600;
  border-color: #e6eaf0;
  color: #000;
}
.table-header-item-con .table-header-button[data-v-d525ced6] {
  cursor: pointer;
  padding: 2px 10px;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.table-header-item-con .table-header-button[data-v-d525ced6]:hover {
    background-color: #e6eaf0;
}
.table-header-item-con i[data-v-d525ced6] {
  cursor: pointer;
}
.table-header-item-con .tab-header-item-icon[data-v-d525ced6] {
  float: right;
}
.popover-list[data-v-d525ced6] {
  margin-top: 10px;
}
.popover-list li[data-v-d525ced6] {
    margin-bottom: 10px;
}
[data-v-d525ced6] .el-button--small span {
  font-size: 14px;
}
.field-management[data-v-d525ced6] {
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  margin: 10px 0 10px 0;
  height: 160px;
  width: 100%;
  overflow-y: auto;
}
.field-management li[data-v-d525ced6] {
    margin-top: 10px;
}
.field-management li[data-v-d525ced6] .el-checkbox__label {
      font-size: 16px;
}
