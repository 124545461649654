.tab-con[data-v-1be0b83d] {
  height: 100%;
  width: 100%;
}
[data-v-1be0b83d] .table-header-style th {
  background-color: #f9fafc;
  font-weight: 600;
  border-color: #e6eaf0;
  color: #000;
}
.process-inst-title[data-v-1be0b83d] {
  margin-left: 10px;
  cursor: pointer;
}
.process-inst-title[data-v-1be0b83d]:hover {
    color: #409eff;
    text-decoration: underline;
}
.boday-header[data-v-1be0b83d] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.boday-header .table-search-state[data-v-1be0b83d] {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.table-toolbar[data-v-1be0b83d] {
  margin-left: 18px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  height: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 4px 0;
}
.table-toolbar .table-toolbar-button[data-v-1be0b83d] {
    font-size: 14px;
    cursor: pointer;
    color: #494f57;
    font-weight: 600;
    line-height: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    border-radius: 3px;
    width: auto;
    padding: 0 8px;
    height: 100%;
}
.table-toolbar .table-toolbar-button i[data-v-1be0b83d] {
      margin-right: 4px;
}
.table-toolbar .table-toolbar-button[data-v-1be0b83d]:hover {
      background-color: #eee;
}
.popover-list[data-v-1be0b83d] {
  margin-top: 10px;
}
.popover-list li[data-v-1be0b83d] {
    margin-bottom: 10px;
}
[data-v-1be0b83d] .el-button--small span {
  font-size: 14px;
}
.table-header-item-con .table-header-button[data-v-1be0b83d] {
  cursor: pointer;
  padding: 2px 10px;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.table-header-item-con .table-header-button[data-v-1be0b83d]:hover {
    background-color: #e6eaf0;
}
.table-header-item-con i[data-v-1be0b83d] {
  cursor: pointer;
}
.table-header-item-con .tab-header-item-icon[data-v-1be0b83d] {
  float: right;
}
.field-management[data-v-1be0b83d] {
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  margin: 10px 0 10px 0;
  height: 160px;
  width: 100%;
  overflow-y: auto;
}
.field-management li[data-v-1be0b83d] {
    margin-top: 10px;
}
.field-management li[data-v-1be0b83d] .el-checkbox__label {
      font-size: 16px;
}
